<script>
import { mapActions } from "vuex";
import IconBase from "../components/icons/_icon-base.vue";
import IconLogoFlat from "@components/icons/_icon-logo-flat.vue";

export default {
  name: "Checkout",
  components: {
    IconBase,
    IconLogoFlat,
  },

  mounted() {
    const $this = this;
    $this.resetModal();
    $this.clearOrder("");
    $this.clearCatalog();
    const utm_referrer =
      window.document.referrer !== "" ? window.document.referrer : "direto";
    const company = this.$route.query.company || "revendaoi";
    const queries = { ...$this.$route.query, utm_referrer, company };
    $this.setQueriesParams(queries);
    $this.setSelectedPlan(queries.sku);
    $this.routerOrchestror(queries);
  },
  methods: {
    ...mapActions({
      resetModal: "modal/resetModal",
      clearOrder: "order/clearData",
      clearCatalog: "catalog/clearData",
      setSelectedPlan: "catalog/setSelectedPlan",
      setQueriesParams: "setQueriesParams",
    }),

    async routerOrchestror(queries) {
      if (queries.redirect) return await this.$router.push(queries.redirect);
      return this.$router.push("/consulta-cobertura");
    },
  },
};
</script>

<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center text-primary"
  >
    <icon-base :size="16"><icon-logo-flat /></icon-base>
  </div>
</template>
