<script>
const _tagTypes = ["h1", "h2", "h3", "h4", "p", "span", "label"];

export default {
  name: "AText",
  props: {
    tag: {
      type: String,
      default: "span",
      validator: value => _tagTypes.includes(value),
    },
    content: {
      type: String,
      required: false,
    },
    for: {
      type: String,
      required: false,
      default: null,
    },
    as: String,
    ignoreStyle: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    getForProp() {
      return ["label"].includes(this.tag) ? this.for : null;
    },
  },
};
</script>

<template lang="pug">
  component(
    :is="tag"
    :for="getForProp"
    :class="ignoreStyle ? 'a-text' :`a-text a-text--${tag}${as ? `-${as}` : ''}` "
    v-bind="{...$attrs}"
    ) {{content}}
      slot
</template>

<style lang="scss" scoped>
.a-text {
  &--label,
  &--p {
    @apply text-dark;
  }
  &--h1 {
    @apply text-primary font-medium text-xl leading-tight;
  }
  &--h2 {
    @apply text-4xl;
    @apply text-primary;
    @apply leading-tight;
  }
  &--h3 {
    @apply text-secondary;
    @apply font-medium;
  }
  &--h4 {
    @apply text-dark-200;
    @apply font-medium;
    @apply text-base;
    @apply flex;
    @apply items-center;
  }
  &--label {
    @apply font-medium;
  }
  &--label {
    @apply text-sm;
    @apply leading-4;
  }
  &--span {
    @apply text-xs;
    @apply leading-none;
    &_label {
      @apply text-sm;
      @apply leading-4;
      @apply block;
    }
  }
}
</style>
