import Vue from "vue";
import Vuelidate from "vuelidate";

import { router, store, App } from "@src/configs";
// import configSentry from "./configs/config.sentry";
import configMock from "./configs/config.mock";
import configGtm from "./configs/config.gtm";
// import tracking from "@src/plugins/tracking";

import "./tricks";
import "./tricks";
import "./design/index.scss";
import "./registerServiceWorker";
import "./assets/tailwind.css";
import "./registerServiceWorker";

Vue.config.productionTip = false;
Vue.use(Vuelidate);
// Vue.use(tracking);

configMock();
configGtm();

const vueApp = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");

// configSentry(vueApp);
export default vueApp;
